define("discourse/plugins/discourse-quiz/discourse/adapters/discourse-quiz-quiz", ["exports", "discourse/plugins/discourse-quiz/discourse/adapters/discourse-quiz-adapter"], function (_exports, _discourseQuizAdapter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _discourseQuizAdapter.default.extend({
    /*
      /quizzes/<quiz_id>
    */
    pathFor(store, type, findArgs) {
      const quiz_id = findArgs["quiz_id"];
      delete findArgs["quiz_id"];
      let path = this.basePath(store, type, findArgs);
      if (quiz_id) {
        path += `/${quiz_id}`;
      }
      return this.appendQueryParams(path, findArgs) + ".json";
    },
    apiNameFor() {
      return "quiz";
    }
  });
});