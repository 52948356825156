define("discourse/plugins/discourse-quiz/discourse/components/question-body", ["exports", "@ember/component", "@ember/object", "discourse-common/utils/decorators"], function (_exports, _component, _object, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend(dt7948.p({
    newQuizOption: "",
    isMultipleChoice(format) {
      return format === "multiple_choice";
    },
    isTrueFalse(format) {
      return format === "true_false";
    },
    isLastQuestion(index, numQuestions) {
      return index === numQuestions - 1;
    },
    isFirstQuestion(index) {
      return index === 0;
    },
    setFormat(newFormat) {
      this.set("format", newFormat);
    },
    setAnswer(newAnswer) {
      this.changeAnswer(this.index, newAnswer);
    },
    removeOption(option_index) {
      this.removeQuestionOption(this.index, option_index);
    },
    addOption() {
      if (this.newQuizOption !== "") {
        this.addQuestionOption(this.index, this.newQuizOption);
        this.set("newQuizOption", "");
        const newOptionInput = document.querySelector(".new-quiz-option input");
        if (newOptionInput) {
          newOptionInput.focus();
        }
      }
    },
    delete() {
      this.deleteQuestion(this.index);
    },
    move(positions) {
      this.moveQuestion(this.index, positions);
    }
  }, [["method", "isMultipleChoice", [(0, _decorators.default)("format")]], ["method", "isTrueFalse", [(0, _decorators.default)("format")]], ["method", "isLastQuestion", [(0, _decorators.default)("index", "numQuestions")]], ["method", "isFirstQuestion", [(0, _decorators.default)("index")]], ["method", "setFormat", [_object.action]], ["method", "setAnswer", [_object.action]], ["method", "removeOption", [_object.action]], ["method", "addOption", [_object.action]], ["method", "delete", [_object.action]], ["method", "move", [_object.action]]]));
});