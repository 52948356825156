define("discourse/plugins/discourse-quiz/discourse/widgets/discourse-quiz", ["discourse/widgets/widget", "discourse/lib/show-modal", "discourse/lib/text", "I18n", "discourse/lib/ajax-error"], function (_widget, _showModal, _text, _I18n, _ajaxError) {
  "use strict";

  (0, _widget.createWidget)("discourse-quiz", {
    tagName: "div.quiz",
    buildKey: attrs => `discourse-quiz-${attrs.id}`,
    defaultState(attrs) {
      return {
        model: attrs.model
      };
    },
    open() {
      return this.store.update("discourse-quiz-quiz", this.state.model.id, {
        is_open: true
      }).then(resp => {
        this.state.model = resp.responseJson.quiz;
      });
    },
    close() {
      return this.store.update("discourse-quiz-quiz", this.state.model.id, {
        is_open: false
      }).then(resp => {
        this.state.model = resp.responseJson.quiz;
      });
    },
    edit() {
      /*
        Open Quiz Builder modal, pre-filled with data from this quiz
      */
      this.store.find("discourse-quiz-question", {
        quiz_id: this.state.model.id
      }).then(resp => {
        (0, _showModal.default)("quiz-ui-builder").setProperties({
          questions: resp.content,
          activeQuestionIndex: 0,
          mode: "update",
          model: this.state.model
        });
      });
    },
    delete() {
      /* Delete the quiz from the post */
      bootbox.confirm(_I18n.default.t("discourse_quiz.ui_builder.confirm_delete_quiz"), _I18n.default.t("no_value"), _I18n.default.t("yes_value"), confirmed => {
        if (confirmed) {
          this.store.find("post", this.state.model.post_id).then(post => {
            const quiz_pattern = /\[quiz[\s\S]*?\[\/quiz\]/;
            const newRaw = post.raw.replace(quiz_pattern, "");
            const props = {
              raw: newRaw,
              edit_reason: _I18n.default.t("discourse_quiz.ui_builder.edit_reason_delete")
            };
            return _text.default.cookAsync(newRaw).then(cooked => {
              props.cooked = cooked.string;
              return post.save(props).catch(e => {
                this.flash((0, _ajaxError.extractError)(e), "error");
              });
            });
          }).catch(e => {
            this.flash((0, _ajaxError.extractError)(e), "error");
          });
        }
      });
    },
    template: function (attrs, state) {
      var __h1 = __widget_helpers.iconNode;
      var __h2 = __widget_helpers.rawHtml;
      var _r = [];
      _r.push("\n    ");
      var _a0 = [];
      _a0.push("\n");
      if (state.model) {
        _a0.push("      ");
        var _a1 = [];
        _a1.push("\n        ");
        var _a2 = [];
        _a2.push("\n          ");
        var _a3 = [];
        _a3.push("\n            ");
        _a3.push(__h1("graduation-cap"));
        _a3.push(" \n              ");
        _a3.push(state.model.title);
        _a3.push("\n          ");
        _a2.push(virtualDom.h('h2', _a3));
        _a2.push("\n          ");
        var _a4 = [];
        _a4.push("\n            ");
        _a4.push(new __h2({
          html: '<span>' + attrs.dates + '</span>'
        }));
        _a4.push("\n            Attempts: ");
        if (state.model.max_attempts) {
          _a4.push(state.model.max_attempts);
        } else {
          _a4.push("Unlimited");
        }
        var _a5 = [];
        _a4.push(virtualDom.h('br', _a5));
        _a4.push("\n            Time limit: ");
        _a4.push(attrs.formattedTimeLimit);
        _a4.push("\n          ");
        _a2.push(virtualDom.h('p', {
          "className": "quiz-info",
          "attributes": {}
        }, _a4));
        _a2.push("\n        ");
        _a1.push(virtualDom.h('div', _a2));
        _a1.push("\n        ");
        var _a6 = [];
        _a6.push("\n          ");
        var _a7 = [];
        _a7.push("\n");
        if (state.model.can_act_on_quiz) {
          _a7.push("              ");
          _a7.push(this.attach("quiz-options", {
            "model": state.model
          }, undefined, undefined));
          _a7.push("\n");
        }
        _a7.push("          ");
        _a6.push(virtualDom.h('div', _a7));
        _a6.push("\n");
        if (!state.model.is_open) {
          _a6.push("            ");
          var _a8 = [];
          _a8.push("\n              ");
          _a8.push(__h1("lock"));
          _a8.push("\n            ");
          _a6.push(virtualDom.h('span', {
            "attributes": {
              "id": "quiz-lock-icon"
            }
          }, _a8));
          _a6.push("\n");
        }
        _a6.push("        ");
        _a1.push(virtualDom.h('div', {
          "className": "quiz-preview-right-side",
          "attributes": {}
        }, _a6));
        _a1.push("\n      ");
        _a0.push(virtualDom.h('div', {
          "className": "quiz-preview-body",
          "attributes": {}
        }, _a1));
        _a0.push("\n");
        if (state.model.is_open) {
          _a0.push("      ");
          var _a9 = [];
          _a9.push("\n          ");
          _a9.push(this.attach("start-quiz-button", undefined, undefined, undefined));
          _a9.push("\n          ");
          _a9.push(this.attach("quiz-results-button", undefined, undefined, undefined));
          _a9.push("\n      ");
          _a0.push(virtualDom.h('div', {
            "className": "quiz-preview-footer",
            "attributes": {}
          }, _a9));
          _a0.push("\n");
        }
      } else {
        _a0.push("      ");
        var _a10 = [];
        _a10.push("\n        ");
        var _a11 = [];
        _a11.push("\n          ");
        var _a12 = [];
        _a12.push(__h1("graduation-cap"));
        _a12.push(" ");
        _a12.push(_I18n.default.t("discourse_quiz.widget.title"));
        _a11.push(virtualDom.h('h2', _a12));
        _a11.push("\n        ");
        _a10.push(virtualDom.h('div', {
          "className": "quiz-preview-body",
          "attributes": {}
        }, _a11));
        _a10.push("\n      ");
        _a0.push(virtualDom.h('div', _a10));
        _a0.push("\n");
      }
      _a0.push("  ");
      _r.push(virtualDom.h('div', {
        "className": "quiz-preview",
        "attributes": {}
      }, _a0));
      _r.push("\n  ");
      return _r;
    }
  });
});