define("discourse/plugins/discourse-quiz/discourse/components/question-option", ["exports", "@ember/component", "@ember/object", "discourse-common/utils/decorators"], function (_exports, _component, _object, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend(dt7948.p({
    isSelected(answer, index) {
      return answer === index || parseInt(answer, 10) === index;
    },
    questionName(questionIndex) {
      return `question_${questionIndex}_answer`;
    },
    radioId(questionIndex, index) {
      return `quiz_question_${questionIndex}_option_${index}`;
    },
    select() {
      this.setAnswer(this.index);
    },
    delete() {
      this.removeOption(this.index);
    }
  }, [["method", "isSelected", [(0, _decorators.default)("answer", "index")]], ["method", "questionName", [(0, _decorators.default)("questionIndex")]], ["method", "radioId", [(0, _decorators.default)("questionIndex", "index")]], ["method", "select", [_object.action]], ["method", "delete", [_object.action]]]));
});