define("discourse/plugins/discourse-quiz/discourse/components/question-number", ["exports", "@ember/component", "discourse-common/utils/decorators"], function (_exports, _component, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend(dt7948.p({
    position(index) {
      return index + 1;
    },
    isActive(activeQuestionIndex, index) {
      return activeQuestionIndex === index;
    },
    actions: {
      setActive() {
        this.changeActive(this.index);
      }
    }
  }, [["method", "position", [(0, _decorators.default)("index")]], ["method", "isActive", [(0, _decorators.default)("activeQuestionIndex", "index")]]]));
});