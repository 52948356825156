define("discourse/plugins/discourse-quiz/discourse/widgets/start-quiz-button", ["exports", "discourse/widgets/widget"], function (_exports, _widget) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _widget.createWidget)("start-quiz-button", {
    tagName: "button.btn.btn-primary",
    click() {
      // this.sendWidgetAction("changeWatchingInviteeStatus", "going");
    },
    template: function (attrs, state) {
      var _r = [];
      _r.push("\n    ");
      var _a0 = [];
      _a0.push("\n      ");
      _a0.push(I18n.t("discourse_quiz.widget.start"));
      _a0.push("\n    ");
      _r.push(virtualDom.h('span', {
        "className": "label",
        "attributes": {}
      }, _a0));
      _r.push("\n  ");
      return _r;
    }
  });
});