define("discourse/plugins/discourse-quiz/discourse/widgets/quiz-options", ["exports", "discourse/widgets/widget"], function (_exports, _widget) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _widget.createWidget)("quiz-options", {
    tagName: "div.quiz-options",
    buildKey: () => "quiz-options",
    transform(attrs) {
      return {
        content: this._buildContent(attrs),
        onChange: item => this.sendWidgetAction(item.id, item.param),
        options: {}
      };
    },
    template: function (attrs, state) {
      var _r = [];
      _r.push("\n    ");
      _r.push(this.attach("widget-dropdown", {
        "id": "quiz-options",
        "icon": "ellipsis-h",
        "label": "discourse_quiz.widget.more",
        "content": this.transformed.content,
        "onChange": this.transformed.onChange,
        "options": this.transformed.options
      }, undefined, undefined));
      _r.push("\n  ");
      return _r;
    },
    _buildContent(attrs) {
      const content = [];
      // Edit
      content.push({
        id: "edit",
        icon: "pencil-alt",
        label: "discourse_quiz.widget.edit"
      });
      // Preview
      content.push({
        id: "preview",
        icon: "far-eye",
        label: "discourse_quiz.widget.preview"
      });

      // Close / Open
      if (attrs.model.is_open) {
        // Close
        content.push({
          id: "close",
          icon: "lock",
          label: "discourse_quiz.widget.close"
        });
      } else {
        // Open
        content.push({
          id: "open",
          icon: "unlock",
          label: "discourse_quiz.widget.open"
        });
      }

      // Delete
      content.push({
        id: "delete",
        icon: "trash-alt",
        label: "discourse_quiz.widget.delete"
      });
      return content;
    }
  });
});