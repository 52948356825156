define("discourse/plugins/discourse-quiz/discourse/adapters/discourse-quiz-attempt", ["exports", "discourse/plugins/discourse-quiz/discourse/adapters/discourse-quiz-adapter"], function (_exports, _discourseQuizAdapter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _discourseQuizAdapter.default.extend({
    pathFor(store, type, findArgs) {
      /*
        /quizzes/<quiz_id>/attempts
      */
      const quiz_id = findArgs["quiz_id"];
      delete findArgs["quiz_id"];
      const id = findArgs["id"];
      delete findArgs["id"];
      let path = this.basePath(store, type, {}) + quiz_id + "/attempts";
      if (id) {
        path += `/${id}`;
      }
      return this.appendQueryParams(path, findArgs);
    }
  });
});