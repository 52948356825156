define("discourse/plugins/discourse-quiz/discourse/initializers/add-quiz-ui-builder", ["exports", "discourse-common/utils/decorators", "discourse/lib/show-modal", "discourse/lib/plugin-api"], function (_exports, _decorators, _showModal, _pluginApi) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function initializeQuizUIBuilder(api) {
    api.modifyClass("controller:composer", dt7948.p({
      canBuildQuiz(discourseQuizEnabled) {
        return discourseQuizEnabled;
      },
      actions: {
        showQuizBuilder() {
          (0, _showModal.default)("quiz-ui-builder").setProperties({
            toolbarEvent: this.toolbarEvent,
            questions: [{
              format: "multiple_choice",
              answer: null,
              options: [],
              text: "",
              error: null
            }],
            activeQuestionIndex: 0,
            mode: "create"
          });
        }
      }
    }, [["method", "canBuildQuiz", [(0, _decorators.default)("siteSettings.discourse_quiz_enabled")]]]));
    api.addToolbarPopupMenuOptionsCallback(composerController => {
      const composerModel = composerController.model;
      if (composerModel && !composerModel.replyingToTopic && (composerModel.topicFirstPost || composerModel.editingPost && composerModel.post && composerModel.post.post_number === 1)) {
        return {
          action: "showQuizBuilder",
          icon: "graduation-cap",
          label: "discourse_quiz.ui_builder.title",
          condition: "canBuildQuiz"
        };
      }
    });
  }
  var _default = _exports.default = {
    name: "add-quiz-ui-builder",
    initialize() {
      (0, _pluginApi.withPluginApi)("0.8.7", initializeQuizUIBuilder);
    }
  };
});